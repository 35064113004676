import { library } from '@fortawesome/fontawesome-svg-core'
import * as SolidSvg from '@fortawesome/free-solid-svg-icons'
import * as BrandsSvg from '@fortawesome/free-brands-svg-icons'

library.add(
  SolidSvg.faTools,
  SolidSvg.faInfo,
  SolidSvg.faCode,
  SolidSvg.faSuitcase,
  SolidSvg.faUniversity,
  SolidSvg.faHome,
  SolidSvg.faEnvelope,
  SolidSvg.faPaintBrush,
  SolidSvg.faHand,
  SolidSvg.faCube,
  SolidSvg.faGlobe,
  SolidSvg.faDatabase,
  SolidSvg.faK,
  SolidSvg.faMicrochip,
  SolidSvg.faToolbox,
  SolidSvg.faCodeBranch,
  BrandsSvg.faGithub,
  BrandsSvg.faHtml5,
  BrandsSvg.faJs,
  BrandsSvg.faPython,
  BrandsSvg.faGit,
  BrandsSvg.faReact,
  BrandsSvg.faUnity,
  BrandsSvg.faAndroid,
  BrandsSvg.faGooglePlay,
  BrandsSvg.faJava,
  BrandsSvg.faLinkedin
)
