import './App.css'
import './icons'
import Galaxy from './components/Galaxy'
import Carousel from './components/carousel/Carousel'
import info from './data/general.json'

const App = () => {
  return (
    <div className='border-box'>
      <Galaxy />
      <div className='app bg-transparent text-white'>
        <Carousel />
        <div className='text-center text-sm py-4'>{info.copyright}</div>
      </div>
    </div>
  )
}

export default App
