import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Skills from './components/Skills'
import Home from './components/Home'
import Education from './components/Education'
import Jobs from './components/Jobs'
import Projects from './components/Projects'
import config from '../../data/config.json'

const slides = [
  <Slide key='home' id='home' title='Home' icon='home' hideTitle={true}>
    <Home />
  </Slide>,
  <Slide key='edu' id='edu' title='Education' icon='university'>
    <Education />
  </Slide>,
  <Slide key='jobs' id='jobs' title='Work Experiences' icon='suitcase'>
    <Jobs />
  </Slide>,
  <Slide key='skills' id='skills' title='Skills' icon='tools'>
    <Skills />
  </Slide>,
  <Slide key='prj' id='prj' title='Projects' icon='code'>
    <Projects />
  </Slide>,
]

function Slide(props) {
  const [forestCreatureCounter, setForestCreatureCounter] = useState(
    config.forestCreatureInitialCounter
  )

  function decrementForestCreatureCounter() {
    setForestCreatureCounter(forestCreatureCounter - 1)
  }

  return (
    <div className='carousel-page flex flex-col mb-6'>
      <div id={props.id} className='w-0 h-0 invisible relative -top-5'>
        Anchor
      </div>
      {!props.hideTitle && (
        <div
          className={
            'tooltip-right tooltip-open self-start select-none' +
            (forestCreatureCounter <= 0 ? ' tooltip' : '')
          }
          data-tip='Yahaha! You found me!'
          onClick={decrementForestCreatureCounter}
        >
          <div className='flex flex-row items-center justify-center border rounded-full px-4 ml-6'>
            <FontAwesomeIcon
              icon={props.icon}
              className='fa-lg text-secondary mr-2'
            />{' '}
            <div className='text-lg'>{props.title}</div>
          </div>
        </div>
      )}
      <div className='px-6'>{props.children}</div>
    </div>
  )
}

function Carousel() {
  return <div>{slides}</div>
}

export default Carousel
