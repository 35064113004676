import education from '../../../data/education.json'

const Education = () => {
  return (
    <div className='carousel-page'>
      <ul className='steps steps-vertical'>
        {education.map((x, i) => (
          <li className='step' key={i}>
            <div className='py-5'>
              <p className='text-start text-lg font-mono'>{x.years}</p>
              <p className='text-start text-xl font-bold'>{x.degree}</p>
              <p className='text-start text-lg fo text-secondary'>{x.school}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Education
