import './Galaxy.scss'

const Galaxy = () => {
  return (
    <div className='galaxy fixed'>
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
    </div>
  )
}

export default Galaxy
