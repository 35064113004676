import Typewriter from 'typewriter-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import links from '../../../data/links.json'
import info from '../../../data/general.json'

const Links = () => {
  return (
    <div className='grid grid-cols-3 mt-12 gap-10'>
      {links.map((x, i) => (
        <div className='text-center' key={i}>
          <a href={x.link}>
            <FontAwesomeIcon icon={x.icon} className='text-4xl text-primary' />
          </a>
        </div>
      ))}
    </div>
  )
}

const Home = () => {
  return (
    <div className='flex flex-col text-center h-screen justify-center items-center'>
      <h1 className='text-6xl'>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString(info.homeGreet1)
              .pauseFor(2000)
              .deleteAll()
              .typeString(info.homeGreet2)
              .pauseFor(10 * 60 * 1000)
              .deleteAll()
              .typeString('Still here?')
              .pauseFor(5 * 60 * 1000)
              .deleteAll()
              .typeString('4 8 14 16 23 42')
              .start()
          }}
        />
      </h1>
      <p className='text-4xl text-gray-600 mt-6 text-secondary'>
        {info.homeSubtitle}
      </p>
      <Links />
    </div>
  )
}

export default Home
