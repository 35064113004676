import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import skills from '../../../data/skills.json'

const Level = (props) => {
  const levels = [
    'Beginner level',
    'Intermediate level',
    'Advanced level',
    'Expert level',
  ]

  return (
    <div
      className='rating w-full items-center tooltip tooltip-bottom mt-4'
      data-tip={levels[props.level - 1]}
    >
      {[...Array(props.level).keys()].map((x) => (
        <input
          key={x}
          disabled={true}
          type='radio'
          name='rating-2'
          className='mask mask-star-2 bg-orange-400'
          checked={true}
        />
      ))}
    </div>
  )
}

const Skills = (props) => {
  function renderItem(x, i) {
    return (
      <div
        className='flex flex-col p-3 m-2 bg-slate-900/[.35] border border-gray-300/[.15]'
        key={i}
      >
        <FontAwesomeIcon
          icon={x.icon}
          className='fa-lg text-primary self-start'
        />
        <div className='text-xl font-bold mt-2'>{x.name}</div>
        <p className='text-md text-gray-300'>{x.description}</p>
        <Level level={x.level} />
      </div>
    )
  }

  return (
    <div className='grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 mt-6'>
      {skills.sort((a, b) => b.level - a.level).map(renderItem)}
    </div>
  )
}

export default Skills
