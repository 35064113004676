import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import projects from '../../../data/projects.json'

const Projects = (props) => {
  function renderLink(link, i) {
    return (
      <a href={link.url} className='text-secondary' key={i}>
        <FontAwesomeIcon icon={link.icon} className='fa-lg' /> {link.label}
      </a>
    )
  }

  function renderItem(x, i) {
    return (
      <div className='m-4' key={i}>
        <p className='text-lg font-bold'>{x.name}</p>
        <p>{x.description}</p>
        <p className='text-gray-400 mt-2'>{x.topics}</p>
        <div className='mt-2 flex flex-col'>{x.links.map(renderLink)}</div>
      </div>
    )
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
      {projects.map(renderItem)}
    </div>
  )
}

export default Projects
