import jobs from '../../../data/jobs.json'

const Jobs = () => {
  return (
    <ul className='steps steps-vertical'>
      {jobs.map((x, i) => (
        <li className='step' key={i}>
          <div className='py-5'>
            <p className='text-start text-lg font-mono'>{x.period}</p>
            <p className='text-start text-xl font-bold'>{x.role}</p>
            <p className='text-start text-lg text-secondary'>{x.company}</p>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default Jobs
